
<template>
  <div id="nav">
    <router-link :to="{path : '/backoffice/point_details', query : {'id' : point_id_prod}}">פרטים</router-link> | 
    <router-link :to="{path : '/backoffice/backoffice_admin_get_distribution_point_statistics',  query : {'id' : point_id_prod}}">סטטיסטיקה</router-link> |
    <router-link :to="{path : '/backoffice/point_admin', query : {'id' : point_id_prod}}">ניהול נק' חלוקה</router-link> | 
    <router-link :to="{path : '/backoffice/users_point', query : {'id' : point_id_prod}}">ניהול משתמשי נק' החלוקה</router-link> 
    <!-- <router-link :to="{path : '/backoffice/point_delete', query : {'id' : point_id_prod}}">מחיקה</router-link>  -->
  </div>
</template>

<script>
export default {
  name: 'DistributionPointMenu',
  props : ['point_id_prod'],
  components: {
  },
  data() {
    return {

    }
  },
  async mounted() {
    
  }
}
</script>

<style scoped>
@import "../../assets/style.css"
</style>