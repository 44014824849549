
<template>
  <BackofficeBase :loader_prop="loader_prop">

    <DistributionPointMenu :point_id_prod="point_id_prod" />
    
    <h1> לקוחות קצה</h1>
    
    <router-link :to="{path : '/backoffice/backoffice_add_enduser', query : {'id' : $route.query.id}}">הוסף לקוח קצה</router-link>
        <div v-for="user in view_data" :key="user.id" class="item_list">
          <p>{{user.name}}</p>
          <p>{{user.phone_number}}</p>
          <p>{{user.email}}</p>
          <router-link :to="{path : '/backoffice/backoffice_user_packages_admin', query : {'phone_number' : user.phone_number}}"> חבילות </router-link> |
          <router-link :to="{path : '/backoffice/backoffice_delete_enduser', query : {'id' : user.id, pid: $route.query.id}}"> מחיקה </router-link>

        </div>
  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";
import BackendModel from "../../models/BackendModel";
import DistributionPointMenu from "./DistributionPointMenu"

export default {
  name: 'BackofficeViewingEndusersAdmin',
  components: {
    BackofficeBase,
    DistributionPointMenu
  },
  data() {
    return {
      loader_prop : true,
      view_data : [],
      point_id_prod : 0
    }

  },
  async mounted() {
    this.id = this.$route.query.id
    this.point_id_prod = this.$route.query.id
    let backendModel = new BackendModel()
    let view_data = await backendModel.backendRequest("/Api/service/backoffice/admin_show_endusers_as_per_distribution_point", {"id": this.id})
    this.view_data = view_data.data.view_data
    this.loader_prop = false
    console.log(view_data)
  },
  methods: { 
  }
}
</script>

<style scoped>
@import "../../assets/style.css"
</style>

